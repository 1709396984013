import React from "react";

const Call = () => {
  return (
    <div>
      <section class="request-call" id="Contact">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="request-call-in">
                <h4>For Further Info/Queries </h4>
                <ul class="contact_info_list d-flex flex-row    ">
                  {/* <!-- <li>
                      <div class="img">
                        <img src="https://www.sharda.ac.in/redcysec/assets/images/mail_icon.png" alt="image">
                      </div>
                      <div class="text">
                        <span>Email Us</span>
                        <a href="mailto:example@gmail.com">redcysec@sharda.ac.in</a>
                      </div>
                    </li> --> */}
                  <li>
                    <div class="img">
                      <img
                        src="https://www.sharda.ac.in/redcysec/assets/images/call_icon.png"
                        alt="image"
                      />
                    </div>
                    <div class="text">
                      <span>Call Us</span>
                      <strong>Prof. Shri Kant</strong>
                      <br />
                      <strong>General Chair</strong>
                      <br />
                      <a href="mailto:example@gmail.com">
                        ccsc.redcysec@sharda.ac.in
                      </a>
                      <br />
                      <small>
                        Head, Center for Cyber Security and Cryptology
                      </small>
                      <br />
                    </div>
                  </li>
                  <li>
                    <div class="img">
                      <img
                        src="https://www.sharda.ac.in/redcysec/assets/images/call_icon.png"
                        alt="image"
                      />
                    </div>
                    <div class="text">
                      <span>Call Us</span>

                      <strong>Dr. Nihar Ranjan Roy</strong>
                      <br />
                      <a href="mailto:example@gmail.com">
                        ccsc.redcysec@sharda.ac.in
                      </a>
                      <br />
                      <a href="tel:+9953121640">+91-9810977908</a>
                      <p></p>
                      <small>
                        Department of Center for Cyber Security and Cryptology{" "}
                      </small>
                    </div>
                  </li>
                  <li>
                    <div class="img">
                      <img
                        src="https://www.sharda.ac.in/redcysec/assets/images/call_icon.png"
                        alt="image"
                      />
                    </div>
                    <div class="text">
                      <span>Call Us</span>

                      <strong>Dr. Amrita</strong>
                      <br />
                      <a href="mailto:example@gmail.com">
                        ccsc.redcysec@sharda.ac.in
                      </a>
                      <br />
                      <a href="tel:+9953121640">+91-9953121640</a>
                      <p></p>
                      <small>
                        Department of Center for Cyber Security and Cryptology{" "}
                      </small>
                    </div>
                  </li>
                  {/* <!--<li>
                      <div class="img">
                        <img src="https://www.sharda.ac.in/redcysec/assets/images/location_icon.png" alt="image">
                      </div>
                      <div class="text">
                        <span>Visit Us</span>
                        <p> plot no 32, 34, Knowledge Park III, Greater Noida, Uttar Pradesh 201310</p>
                      </div>
                    </li>--> */}
                </ul>
                {/* <!--<h4>Want to Register for Qawwali Competition </h4>
                    <a href="#" class="button">Register Now</a>--> */}
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Call;
