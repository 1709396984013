import React from "react";

const TrackDetails = () => {
  return (
    <div className="container py-4">
      <div
        className="section-title text-center position-relative pb-3 mb-2 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Key Tracks</h5>
        <h2 className="mb-0">Track Details</h2>
      </div>
      <p>
        The International Conference ICACCTech, 2024 invites original
        contributions that have not been published or communicated anywhere
        else, to this blind and peer-reviewed conference. Below are the key
        tracks for the conference:
      </p>
      <div className="row">
        <div className="col-md-6">
          <ul>
            <li>
              <h5>◆ Artificial Intelligence and Machine Learning</h5>
              <p className="text-justify">
                Advances in AI and ML algorithms, reinforcement learning, neural
                networks, deep learning models, and applications of AI/ML in
                various domains such as robotics, natural language processing,
                and computer vision.
              </p>
            </li>
            <li>
              <h5>
                ◆ Digital Security, Information Privacy and Web Technology
              </h5>
              <p className="text-justify">
                Enhancements in web security frameworks, data privacy policies,
                encryption techniques, identity management, and safeguarding
                digital communications.
              </p>
            </li>
            <li>
              <h5>◆ Internet of Everything and Sensor Networks</h5>
              <p className="text-justify">
                Integration of IoT systems with sensor networks, security
                issues, smart environments, and the communication protocols
                enabling sensor-based ecosystems.
              </p>
            </li>
            <li>
              <h5>
                ◆ Healthcare Analytics, CoVID-19 Technologies and Data Analysis
              </h5>
              <p className="text-justify">
                Use of data analytics in healthcare, predictive models, CoVID-19
                related innovations, healthcare data privacy, and the impact of
                AI in healthcare decision-making processes.
              </p>
            </li>
            <li>
              <h5>◆ Software Engineering and Cloud Computing</h5>
              <p className="text-justify">
                Innovations in software development methodologies, agile and
                DevOps practices, advancements in cloud platforms, cloud-native
                architectures, and service-oriented computing.
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul>
            <li>
              <h5>◆ Digital Image and Video Processing</h5>
              <p className="text-justify">
                Image recognition, video analytics, computer vision
                advancements, pattern recognition, and processing algorithms
                applied in various fields.
              </p>
            </li>
            <li>
              <h5>◆ Circuits and Systems and Quantum Computing</h5>
              <p className="text-justify">
                Development of advanced circuit designs, VLSI, quantum computing
                principles, algorithms, and their application in cryptography
                and communication.
              </p>
            </li>
            <li>
              <h5>◆ Communication Technologies</h5>
              <p className="text-justify">
                Recent trends in 5G/6G, satellite communications, wireless and
                optical communication, and advancements in network protocols.
              </p>
            </li>
            <li>
              <h5>◆ E-Commerce and E-Governance</h5>
              <p className="text-justify">
                Emerging trends in digital transactions, blockchain applications
                in governance, secure payment systems, and public policy
                implications of digital governance.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TrackDetails;
