import React from "react";

const Marque = () => {
  return (
    <div>
      <div className="marque">
        {" "}
        <div className="ticker">
          {" "}
          <div className="title-marque text-center">
            <h3>Announcement</h3>
          </div>{" "}
          <div className="news">
            {" "}
            <marquee className="news-content">
              {" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
              <p>Conference Schedule is out!!</p> <p>Conference Schedule is out!!</p>{" "}
            </marquee>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </div>
  );
};

export default Marque;
