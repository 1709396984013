import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SpecialSession from "./pages/SpecialSession";
import Committee from "./pages/Committee";
import Registration from "./pages/Registration";
import Cfp from "./pages/Cfp";
import Past from "./pages/Past";
import Guidelines from "./pages/Guidelines";
import ReactGA from "react-ga4";

const App = () => {
  ReactGA.initialize("G-GNF96QSZ19");
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/special-session" element={<SpecialSession />} />
      <Route path="/committee" element={<Committee />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/cfp" element={<Cfp />} />
      <Route path="/past-conf" element={<Past />} />
      <Route path="/guidelines" element={<Guidelines />} />
    </Routes>
  );
};

export default App;
