import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import Call from "../components/Call";
import InnerHead from "../components/InnerHead";
import Hero from "../components/Hero";

const Registration = () => {
  return (
    <div>
      <Header />
      {/* <InnerHead /> */}
      <Hero />
      <section class="his-contant">
        <div
          className="section-title text-center position-relative pb-3 mb-2 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Registration</h5>
          <h1 className="mb-0">Registration Details</h1>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <table class="table-12">
                <tbody>
                  <tr>
                    <th colspan="2" rowspan="3">
                      Category
                    </th>
                    <th colspan="4">Registration Fee</th>
                  </tr>
                  <tr>
                    <th colspan="2">National</th>
                    <th colspan="2">International</th>
                  </tr>
                  <tr>
                    <th>Early Bird Registration</th>
                    <th>Late Registration</th>
                    <th>Early Bird Registration</th>
                    <th>Late Registration</th>
                  </tr>
                  <tr>
                    <th rowspan="3">Author</th>
                    <th>&nbsp;Research Scholar&nbsp;/ Student (Full Time)</th>
                    <td>6000/-</td>
                    <td>7000/-</td>
                    <td>200&nbsp;USD</td>
                    <td>250&nbsp;USD</td>
                  </tr>
                  <tr>
                    <th>Academician</th>
                    <td>7000/-</td>
                    <td>8000/-</td>
                    <td>300&nbsp;USD</td>
                    <td>350&nbsp;USD</td>
                  </tr>
                  <tr>
                    <th>Industry</th>
                    <td>10000/-</td>
                    <td>12000/-</td>
                    <td>400&nbsp;USD</td>
                    <td>500&nbsp;USD</td>
                  </tr>
                  <tr>
                    <th rowspan="3">Delegate (attendees)</th>
                    <th>Student / Research Scholar</th>

                    <td colspan="2">500/-</td>
                    <td colspan="2">50 USD</td>
                  </tr>
                  <tr>
                    <th>Academician</th>
                    <td colspan="2">1000/-</td>
                    <td colspan="2">50 USD</td>
                  </tr>
                  <tr>
                    <th>Industry</th>
                    <td colspan="2">1500/-</td>
                    <td colspan="2">100 USD</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-12 text-center my-4">
            <div class="header-contant">
              <a href="https://acctcomputing.netlify.app/" class="button1">
                Register Now
              </a>
            </div>
          </div>

          <div class="detail_guid">
            <strong>Note</strong>
            <p>
              1. Registration fee is non-refundable,non-transferable and
              includes 18% GST.
              <br />
              2. Certificate will be issued only to the presenter of the paper
              (during oral presentation). At least one author must have
              registered and paid the registration fee.
              <br />
              3. All the registered papers must be presented, then only it will
              be forwarded for final publication.
              <br />
              4. If more than one author will be coming to attend the conference
              for paper presentation then all the authors he/she also needs to
              register.{" "}
            </p>
          </div>
        </div>
        <Call />
      </section>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Registration;
