import React from "react";

const AboutUs = () => {
  return (
    <div>
      <div className="container my-5 p-4 border rounded bg-light shadow-sm">
        <div
          className="section-title text-center position-relative pb-3 mb-2 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">
            About the Conference
          </h5>
          <h2 className="mb-0 w-full">Welcome to 2nd ICACCTech 2024</h2>
        </div>
        <hr />
        <p className="text-justify">
          <b>
            Novel Research Foundation is organizing 2nd International Conference
            on Advanced Computing & Communication Technologies (2nd ICACCTech
            2024) in association with Jan Wyżykowski University, Polkowice, Poland & BM Institute of Engineering and
            Technology, Sonipat, Haryana at Ethnic India, Rai,Sonipat,
            Delhi-NCR, India on 16-17 November 2024.
          </b>
          <br />
          2nd International Conference on Advanced Computing & Communication
          Technologies (2nd ICACCTech 2024) promises to address current state of
          the technology and the outcome of the ongoing research in the area of
          advance computing and communication. Computer and communication has
          immensely enhanced the level of information acquisition, retrieval and
          processing. 2nd ICACCTech 2024 would offer a forum to the
          academicians, researchers and students to interact with experts in the
          area and to learn the recent knowledge in advance computing. We invite
          original & unpublished work from individuals active in the broad theme
          of the conference.
          <br />
          <b>
            Conference proceedings will be published with ISBN number in
            electronic form by CPS and shall be submitted to Xplore and CSDL by
            CPS.
          </b>
        </p>
      </div>
      <div className="container my-5">
        <div className="p-4 rounded bg-dark text-white shadow-sm">
          <h3 className="fw-bold">Conference Record Number - 65084</h3>
          <h3 className="fw-bold">
            <a href="https://www.ieee.org/conferences/organizers/conf-app.html?confRecNum=65084">
              Conference Approval Link
            </a>
          </h3>
          <p>
            <b>
              All accepted papers will be submitted by CPS to the indexing
              companies for possible indexing (Scopus, Ei Compendex and other
              major Indexes).
            </b>
            <br />
            <br />
            1st International Conference on Advanced Computing & Communication
            Technologies was held at Swami Vivekanand Institute of Engineering
            and Technology was held at 23-24 December 2023.
            <br />
            <b>All papers of 1st ICACCTech 2023 were published in xplore.</b><br/>
            Link of 1st ICACCTech 2023 published papers:{" "}
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10440965/proceeding">
              Xplore - Conference Table of Contents
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
