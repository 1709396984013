import React from "react";
import InnerHead from "../components/InnerHead";
import Header from "../components/Header";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import TrackDetails from "../components/TrackDetails";

const Guidelines = () => {
  return (
    <div>
      <Header />
      <InnerHead />
      <div
        className="section-title text-center position-relative py-3 my-2 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase">
          Guidelines
        </h5>
        <h2 className="mb-0">ICACCTech 2024</h2>
      </div>
      <div className="container my-5">
        <div className="card shadow-lg">
          <div className="card-body">
            <p className="card-text text-justify">
              <b>Guidelines For Authors: </b>Authors should submit their papers
              online using the{" "}
              <a href="https://cmt3.research.microsoft.com/ACCT2024">
                Microsoft CMT Paper Submission Link.
              </a>
              Unregistered authors should first create an account on Microsoft
              CMT to log on. The submission Web site for 2nd ICACCTech 2024 is
              through online submission process using{" "}
              <a href="https://cmt3.research.microsoft.com/ACCT2024">
                Microsoft CMT Paper Submission Link.
              </a>
              <br />
              <br />
              The manuscripts should be submitted in .Doc or PDF format.
              <br />
              <br />
              All papers that conform to submission guidelines will be peer
              reviewed and evaluated based on originality, technical and/or
              research content/depth, correctness, relevance to conference,
              contributions, and readability. Acceptance of papers will be
              communicated to authors by email. The authors of the accepted
              papers will be allowed to make corrections in accordance with the
              suggestions of the reviewers and submit final camera-ready papers
              within the stipulated deadline.
              <br />
              <br />
              <b>Before uploading your camera ready paper, please:</b>
            </p>

            <ul>
              <li>
                1. Upload the full length manuscript in online process using
                Microsoft CMT Paper Submission Link.
              </li>
              <li>
                2. Use Title Case for the paper title, i.e. capitalizing all
                main words.
              </li>
              <li>
                3. Do not use academic titles such as Dr., Professor, HoD,
                Director etc…in your paper.
              </li>
              <li>
                4. Authors are requested to submit paper through given link:{" "}
                <a href="https://cmt3.research.microsoft.com/ACCT2024">
                  Microsoft CMT Paper Submission Link.
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Guidelines;
