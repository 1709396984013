import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import AdvisoryCommittee from "../components/AdvisoryCommittee";
import InnerHead from "../components/InnerHead";

const Committee = () => {
  return (
    <div>
      <Header />
      <InnerHead />
      <AdvisoryCommittee />
    </div>
  );
};

export default Committee;
