import React from "react";
import Header from "../components/Header";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import InnerHead from "../components/InnerHead";
import Hero from "../components/Hero";
import Call from "../components/Call";

export const SpeakerCard = ({ speaker }) => (
  <div className="col-lg-3 col-md-4 col-sm-6 my-4">
    <div className="card shadow-sm">
      <div className="overflow-hidden">
        <img className="card-img-top" src={speaker.image} alt={speaker.name} />
      </div>
      <div className="card-body text-center">
        <h5 className="card-title text-primary">{speaker.name}</h5>
        <p className="card-text">{speaker.designation}</p>
        <a className="text-danger" href={`mailto:${speaker.email}`}>
          {speaker.email}
        </a>
      </div>
    </div>
  </div>
);

const SpecialSession = () => {
  const conferenceData = {
    speakers1: [
      {
        name: "Dr Sanjay Singla",
        designation: "Department of CSE, Chandigarh University, Punjab, India",
        // email: "naresh@unizwa.edu.om",
        image: "assets/img/1.jpg",
      },
      {
        name: "Dr Raman Chadha",
        designation: "Department of CSE, Chandigarh University, Punjab, India",
        // email: "chaman@inf.elte.hu",
        image: "assets/img/2.jpg",
      },
      {
        name: "Dr Anuj Jainl",
        designation: "Department of CSE, Chandigarh University, Punjab, India",
        // email: "drsbgoyal@gmail.com",
        image: "assets/img/3.jpg",
      },
      {
        name: "Dr. Kusum Yadav",
        designation:
          "College of Computer Science and Engineering, University of Ha'il, Kingdom of Saudi Arabia",
        // email: "kusum.yadav@uoh.edu.sa",
        image: "assets/img/4.jpg",
      },
    ],
    speakers2: [
      {
        name: "Dr. Anuj Kumar Gupta",
        designation:
          "Professor, Department of Computer Science & Engineering, CGC College of Engineering, Landran, Mohali (PB), India",
        email: "cgccoe.cse.anuj@gmail.com",
        image: "assets/img/5.jpg",
      },
      {
        name: "Dr. Manvinder Sharma",
        designation:
          "Associate Professor, Department of Interdisciplinary Courses in Engineering (DICE), Chitkara University, Punjab",
        email: "manvinder.sharma@gmail.com",
        image: "assets/img/6.jpg",
      },
    ],
    speakers3: [
      {
        name: "Ms Mnka Sharma",
        designation: "Assistant Professor GDGU, Gurugram",
        // email: "manvinder.sharma@gmail.com",
        image: "assets/img/11.jpg",
      },

      {
        name: "Dr. Puneet Garg",
        designation: "Associate Professor SAITM, Gurugram",
        // email: "manvinder.sharma@gmail.com",
        image: "assets/img/10.jpg",
      },
    ],
    speakers4: [
      {
        name: "Prof. (Dr.) Surjeet Dalal",
        designation: "Amity University Gurugram, Haryana India",
        // email: "cgccoe.cse.anuj@gmail.com",
        image: "assets/img/7.jpg",
      },
      {
        name: "Dr. Uma Rani",
        designation:
          "Dr. Akhilesh Das Gupta Institute of Professional Studies, Delhi, India",
        // email: "manvinder.sharma@gmail.com",
        image: "assets/img/8.jpg",
      },
    ],
    speakers5: [
      {
        name: "Dr. Priyanka Mishra",
        designation:
          "Professor in Computer Science & Engg. Dept. at Compucom Institute of Technology & Management, Jaipur",
        // email: "cgccoe.cse.anuj@gmail.com",
        image: "assets/img/12.jpg",
      },
      {
        name: "Dr. Sheetal Agarwal",
        designation:
          "Associate Professor & Head in  Civil Engineering Department at Compucom Institute of Technology & Management, Jaipur",
        // email: "manvinder.sharma@gmail.com",
        image: "assets/img/13.jpg",
      },
    ],
  };

  return (
    <div>
      <Header />
      <InnerHead />
      <div className="container my-3 mt-4 pt-4">
        <div
          className="section-title text-center position-relative pb-3 mb-2 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">
            Special Sessions
          </h5>
          {/* <h1 className="mb-0">Keynote Speakers</h1> */}
        </div>
        <div className="mt-4">
          <h3 className="text-center">
            <b>
              Special Session 1-: Application of Advanced Computing in
              Engineering Applications
            </b>
          </h3>
        </div>
        <div className="row justify-content-center">
          {conferenceData.speakers1.map((speaker, index) => (
            <SpeakerCard key={index} speaker={speaker} />
          ))}
        </div>
        <div className="mt-4">
          <h3 className="text-center">
            <b>
              Special Session 2: Emerging Network & Communication Technologies
            </b>
          </h3>
        </div>
        <div className="row justify-content-center">
          {conferenceData.speakers2.map((speaker, index) => (
            <SpeakerCard key={index} speaker={speaker} />
          ))}
        </div>
        <div className="mt-4">
          <h3 className="text-center">
            <b>
              Special Session 3: Advancements in Generative Artificial
              Intelligence for Society 5.0
            </b>
          </h3>
        </div>
        <div className="row justify-content-center">
          {conferenceData.speakers3.map((speaker, index) => (
            <SpeakerCard key={index} speaker={speaker} />
          ))}
        </div>
        <div className="mt-4">
          <h3 className="text-center">
            <b>
              Special Session 4-: Recent Trends in Computational Techniques
              Using Data Science and Machine Learning Approaches
            </b>
          </h3>
        </div>
        <div className="row justify-content-center">
          {conferenceData.speakers4.map((speaker, index) => (
            <SpeakerCard key={index} speaker={speaker} />
          ))}
        </div>
        <div className="mt-4">
          <h3 className="text-center">
            <b>
              Special Session 5-: Statistics for Data Science and Data Analytics
            </b>
          </h3>
        </div>
        <div className="row justify-content-center">
          {conferenceData.speakers5.map((speaker, index) => (
            <SpeakerCard key={index} speaker={speaker} />
          ))}
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default SpecialSession;
