import React from "react";
import InnerHead from "../components/InnerHead";
import Header from "../components/Header";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";

const Past = () => {
  return (
    <div>
      <Header />
      <InnerHead />
      <div
        className="section-title text-center position-relative py-3 my-2 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase">
          Past Conferences
        </h5>
        <h2 className="mb-0">Past Conferences</h2>
      </div>
      <div className="container my-5">
        <div className="card shadow-lg  bg-light">
          <div className="card-body text-center">
            <h3 className="card-title mb-3">
              International Conference On Advanced Computing And Communication
              Technologies
            </h3>
            <p className="card-text">
              <strong>Venue:</strong> Swami Vivekanand Institute of Engineering
              and Technology, Banur, Chandigarh, India
            </p>
            <p className="card-text">
              <strong>Date:</strong> 23-24 December 2023
            </p>
            <p className="card-text">
              Conference proceedings published in electronic form by CPS and
              available at Xplore.
            </p>
            <p className="card-text">
              <strong>ICACCTech 2023</strong>
            </p>
            <p className="card-text">
              <strong>IEEE Catalog Number:</strong> CFP23UN0-ART
            </p>
            <p className="card-text">
              <strong>ISBN:</strong> 979-8-3503-8088-0
            </p>
            <a
              href="https://ieeexplore.ieee.org/xpl/conhome/10440965/proceeding"
              className="btn btn-success mt-4"
            >
              Proceedings Link
            </a>
          </div>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Past;
